// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css'; 
import HomePage from './views/Home/HomePage';
import MenuView from './views/MenuView';

function App() {
  return (
    <Router>
      <Routes>
        {/* تعريف الصفحة الرئيسية على المسار '/' */}
        <Route path="/" element={<HomePage />} />
        {/* تعريف المسار '/menu' */}
        <Route path="/menu" element={<MenuView />} />
        {/* مسار لأي صفحة غير موجودة */}
        <Route path="*" element={<div>الصفحة غير موجودة</div>} />
      </Routes>
    </Router>
  );
}

export default App;
