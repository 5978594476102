// HomePage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
  const navigate = useNavigate();

  const goToMenu = () => {
    navigate('/menu');
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gradient-to-r from-[#073F26] to-[#AE8849]">
      <div className="text-center text-white flex flex-col items-center">
        <h1 className="text-6xl  p-3  bg-clip-text text-transparent bg-gradient-to-r from-white to-[#AE8849] mb-8">Coming Soon</h1>
        <button
          onClick={goToMenu}
          className="px-8 py-4 text-xl text-orange-500 bg-white rounded-md hover:bg-gray-200 transition duration-300"
        >
          Go To Menu
        </button>
      </div>
    </div>
  );
};

export default HomePage;
