import React, { useState, useRef, useEffect, useMemo } from 'react';
import './ImageFlipBook.css';
import Loader from '../components/loader/Loader';

// استيراد الصور
import imageA from '../assets/img/1.jpg';
import imageB from '../assets/img/2.jpg';
import imageC from '../assets/img/3.jpg';
import imageD from '../assets/img/4.jpg';
import imageE from '../assets/img/5.jpg';
import imageF from '../assets/img/6.jpg';
import imageG from '../assets/img/7.jpg';
import imageH from '../assets/img/8.jpg';
import imageI from '../assets/img/9.jpg';
import imageJ from '../assets/img/10.jpg';
import imageK from '../assets/img/11.jpg';
import imageL from '../assets/img/12.jpg';
import imageM from '../assets/img/13.jpg';
import imageO from '../assets/img/14.jpg';
import imageP from '../assets/img/15.jpg';
import imageQ from '../assets/img/16.jpg';
import imageR from '../assets/img/17.jpg';

const MenuView = () => {
  const [currentLocation, setCurrentLocation] = useState(1);
  const [isLoading, setIsLoading] = useState(true); // حالة التحميل
  const [loadedImages, setLoadedImages] = useState(0); // عدد الصور المحملة
  const containerRef = useRef(null);
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);

  // استخدام useMemo لتعريف images مرة واحدة
  const images = useMemo(
    () => [
      { id: 1, src: imageA ,alt: 'صورة 1' },
      { id: 2, src: imageB, alt: 'صورة 2' },
      { id: 3, src: imageC, alt: 'صورة 3' },
      { id: 4, src: imageD, alt: 'صورة 4' },
      { id: 5, src: imageE, alt: 'صورة 5' },
      { id: 6, src: imageF, alt: 'صورة 6' },
      { id: 7, src: imageG, alt: 'صورة 7' },
      { id: 8, src: imageH, alt: 'صورة 8' },
      { id: 9, src: imageI, alt: 'صورة 9' },
      { id: 10, src: imageJ, alt: 'صورة 10' },
      { id: 11, src: imageK, alt: 'صورة 11' },
      { id: 12, src: imageL, alt: 'صورة 12' },
      { id: 13, src: imageM, alt: 'صورة 13' },
      { id: 14, src: imageO, alt: 'صورة 14' },
      { id: 15, src: imageP, alt: 'صورة 15' },
      { id: 16, src: imageQ, alt: 'صورة 16' },
      { id: 17, src: imageR, alt: 'صورة 17' },
    ],
    []
  );

  const numOfPages = images.length;

  // تحميل جميع الصور وتتبع التحميل
  useEffect(() => {
    let isMounted = true;
    images.forEach((image) => {
      const img = new Image();
      img.src = image.src;
      img.onload = () => {
        if (isMounted) {
          setLoadedImages((prev) => prev + 1);
        }
      };
      img.onerror = () => {
        console.error(`Failed to load image: ${image.src}`);
        if (isMounted) {
          setLoadedImages((prev) => prev + 1);
        }
      };
    });

    return () => {
      isMounted = false;
    };
  }, [images]);

  // تحديث حالة التحميل بناءً على loadedImages
  useEffect(() => {
    if (loadedImages >= images.length) {
      setIsLoading(false);
      console.log('All images loaded.');
    }
  }, [loadedImages, images.length]);

  // التعامل مع بدء اللمس
  const handleTouchStart = (e) => {
    touchStartX.current = e.changedTouches[0].screenX;
  };

  // التعامل مع نهاية اللمس
  const handleTouchEnd = (e) => {
    touchEndX.current = e.changedTouches[0].screenX;
    handleGesture();
  };

  // التعامل مع بدء النقر بالماوس
  const handleMouseDown = (e) => {
    e.preventDefault();
    touchStartX.current = e.screenX;
  };

  // التعامل مع نهاية النقر بالماوس
  const handleMouseUp = (e) => {
    e.preventDefault();
    touchEndX.current = e.screenX;
    handleGesture();
  };

  // التعامل مع الإيماءات (السحب)
  const handleGesture = () => {
    const threshold = 20; // حساسية اللمس
    if (touchEndX.current < touchStartX.current - threshold) {
      goNext();
    }
    if (touchEndX.current > touchStartX.current + threshold) {
      goPrev();
    }
  };

  // التنقل إلى الصفحة التالية
  const goNext = () => {
    if (currentLocation < numOfPages) {
      setCurrentLocation(currentLocation + 1);
    }
  };

  // الرجوع إلى الصفحة السابقة
  const goPrev = () => {
    if (currentLocation > 1) {
      setCurrentLocation(currentLocation - 1);
    }
  };

  return (
    <>
      {isLoading ? (
        // عنصر الانتظار (يمكنك تصميمه كما تشاء)
        <div className="loading">
          <Loader />
          <p>
            جاري تحميل {loadedImages} من {images.length} صورة
          </p>
        </div>
      ) : (
        <div
          className="container-menu"
          ref={containerRef}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
        >
          <div id="book">
            {images.map((image, index) => (
              <div
                key={image.id}
                className={`page ${currentLocation > index + 1 ? 'flipped' : ''}`}
                style={{ zIndex: images.length - index }}
              >
                <div className="content">
                  <img src={image.src} alt={image.alt} />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default MenuView;
